import React from "react";
import PropTypes from "prop-types";
import Head from "next/head";

import AppBar from "./AppBar";
import Footer from "./Footer";
import ChampProgressBar from "./ChampProgressBar";
import config from "../config";

const Layout = ({
  children,
  pageTitle,
  pageTitlePrefix,
  pageDescription,
  bgColor,
  footer,
  header,
  progressBar,
  canonicalLinkPath,
  videoState,
}) => (
  <>
    <Head>
      <meta name="description" content={pageDescription} />
      <title>{`${pageTitlePrefix} ${pageTitle || ""}`}</title>
      {canonicalLinkPath ? (
        <link rel="canonical" href={`${config.WEB_URL}${canonicalLinkPath}`} />
      ) : null}
    </Head>
    <div className="gn-page h-full">
      {header ? <AppBar /> : null}
      <main role="main" className={`${bgColor} flex flex-col gn-main h-full`}>
        <h1 className="sr-only">{pageTitle} Page</h1>
        {progressBar ? <ChampProgressBar videoState={videoState} /> : null}
        {children}
      </main>
      {footer ? <Footer /> : null}
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  pageTitlePrefix: PropTypes.string,
  pageDescription: PropTypes.string,
  bgColor: PropTypes.string,
  footer: PropTypes.bool,
  header: PropTypes.bool,
  progressBar: PropTypes.bool,
  canonicalLinkPath: PropTypes.string,
};

Layout.defaultProps = {
  bgColor: "bg-gray-900",
  pageTitle: undefined,
  pageTitlePrefix: "GoNoodle",
  pageDescription:
    "Get Your Kids Moving & Practicing Mindfulness with GoNoodle®. Start Watching Now & Get Access to Hundreds of Videos! Active Learning. Healthy Kids. Active Kids. Boost Learning.",
  footer: true,
  header: true,
  progressBar: false,
  canonicalLinkPath: undefined,
};

export default Layout;
