import { useRouter } from "next/router";
import NextImage from "next/image";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";

import Link from "../Link";
import { ROUTE_PREFIX } from "../../constants";

const KID_SAFE_SEAL = {
  BASE_URL: "https://www.kidsafeseal.com/",
  SUPERNOODLE_IMAGE: "3212606891773812580/supernoodle_svg_whitetm.svg",
  SUPERNOODLE_ALT: "SuperNoodle site is a member of the kidSAFE Seal Program.",
  SUPERNOODLE_LINK: "supernoodle.html",
  GONOODLE_IMAGE: "877468999199877006/gonoodle_web_families_svg_whitetm.svg",
  GONOODLE_ALT: "COPPA Certified - Kid Safe",
  GONOODLE_LINK: "gonoodle_web_families.html",
};
const linkProps = {
  title: "kidSAFE Seal",
  sourceElement: "footer",
  sourceName: "footer",
};

export default function KidSafeSeal() {
  const router = useRouter();
  const platform = router.pathname.startsWith(`/${ROUTE_PREFIX.CURRICULUM}/`)
    ? "SUPERNOODLE"
    : "GONOODLE";

  return (
    <Link
      to={`${KID_SAFE_SEAL.BASE_URL}certifiedproducts/${
        KID_SAFE_SEAL[`${platform}_LINK`]
      }`}
      className="block w-2xl justify-center pb-md md:pb-0 md:w-28 md:mr-md md:align-center"
      events={[
        {
          event: "Link Clicked",
          properties: {
            ...linkProps,
            ...getUtmParamsFromQueryParams(router.query),
          },
        },
      ]}
      queryParams={linkProps}
    >
      <span className="sr-only">
        GoNoodle websites for families (home version) is certified by the
        kidSAFE Seal Program.
      </span>
      <NextImage
        alt={KID_SAFE_SEAL[`${platform}_ALT`]}
        src={`${KID_SAFE_SEAL.BASE_URL}sealimage/${
          KID_SAFE_SEAL[`${platform}_IMAGE`]
        }`}
        width="112"
        height="44"
      />
    </Link>
  );
}
