function getAllJWPlayerElements() {
  return typeof window !== "undefined"
    ? document.querySelectorAll('[id^="player"]')
    : [];
}

export function pauseAllJWPlayers() {
  const JWPlayerElements = getAllJWPlayerElements();

  JWPlayerElements.forEach((player) => {
    if (player && player.id && window.jwplayer) {
      const currentPlayer = window.jwplayer(player.id);

      if (currentPlayer) {
        currentPlayer.pause();
        currentPlayer.pauseAd();
      }
    }
  });
}
